// app/javascript/controllers/multistep_form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "label",
    "progressBar",
    "previousButton",
    "nextButton",
    "submitButton",
  ];
  static values = {
    currentStep: Number,
    totalSteps: Number,
    factors: Array,
    states: Array,
    questions: Array,
  };

  connect() {
    this.updateStep();
    this.updateButtons();
    this.updateProgressBar();
  }

  nextStep() {
    if (this.currentStepValue < this.totalStepsValue - 1) {
      this.currentStepValue++;
      this.updateStep();
      this.updateButtons();
      this.updateProgressBar();
    }
  }

  previousStep() {
    if (this.currentStepValue > 0) {
      this.currentStepValue--;
      this.updateStep();
      this.updateButtons();
      this.updateProgressBar();
    }
  }

  updateStep() {
    const step = this.getCurrentStep();
    this.labelTarget.textContent = step.label;
    this.inputTarget.name = step.name;
    this.inputTarget.type = step.type;
    if (step.type === "number") {
      this.inputTarget.min = 1;
      this.inputTarget.max = 5;
    }
  }

  updateButtons() {
    this.previousButtonTarget.classList.toggle(
      "hidden",
      this.currentStepValue === 0,
    );
    this.nextButtonTarget.classList.toggle(
      "hidden",
      this.currentStepValue === this.totalStepsValue - 1,
    );
    this.submitButtonTarget.classList.toggle(
      "hidden",
      this.currentStepValue !== this.totalStepsValue - 1,
    );
  }

  updateProgressBar() {
    const progress = ((this.currentStepValue + 1) / this.totalStepsValue) * 100;
    this.progressBarTarget.style.width = `${progress}%`;
  }

  getCurrentStep() {
    const allSteps = [
      ...this.factorsValue,
      ...this.statesValue,
      ...this.questionsValue,
    ];
    return allSteps[this.currentStepValue];
  }
}
