import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "messageInput",
    "container",
    "reflectionEntryTitle",
    "aiMessage",
    "aiStatus",
  ];

  connect() {
    this.debouncedSendMessage = this.debounce(
      this.sendMessage.bind(this),
      5000,
    );

    if (this.hasMessageInputTarget) {
      this.messageInputTarget.addEventListener(
        "input",
        this.debouncedSendMessage,
      );
    }
  }

  disconnect() {
    if (this.hasMessageInputTarget) {
      this.messageInputTarget.removeEventListener(
        "input",
        this.debouncedSendMessage,
      );
    }
  }

  debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  typeMessage(message, element, delay = 25) {
    const statusElement = this.aiStatusTarget;
    let i = 0;

    const typeChar = () => {
      if (i < message.length) {
        element.textContent += message.charAt(i);
        i++;
        setTimeout(typeChar, delay);
      } else {
        statusElement.textContent = i18n.t("zero_pole_recommends");
      }
    };

    element.textContent = ""; // Clear previous message
    typeChar();
  }

  sendMessage() {
    const formData = new FormData();
    formData.append("message", this.messageInputTarget.value);
    if (this.hasReflectionEntryTitleTarget) {
      formData.append("question", this.reflectionEntryTitleTarget.innerText);
    }

    fetch("/prompt/deepen_question", {
      method: "POST",
      body: formData,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        this.containerTarget.classList.remove("hidden");
        setTimeout(() => {
          this.typeMessage(data.text, this.aiMessageTarget);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setTimeout(() => {
          this.typeMessage("Error 👀", this.aiMessageTarget);
        }, 2000);
        this.containerTarget.classList.remove("hidden");
      });
  }
}
