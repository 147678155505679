// Entry point for the build script in your package.json
import "@hotwired/stimulus";
import "@hotwired/turbo-rails";
import "@tailwindcss/forms";
import "classnames";
import "preline";

// translations
import { I18n } from "i18n-js";
import translations from "./locales.json";
const i18n = new I18n(translations);
i18n.locale = document.body.dataset.locale;
// make it accessible everywhere
window.i18n = i18n;


// react components
import { Application } from "@hotwired/stimulus";
// Stimulus
import MultistepFormController from "./controllers/multistep_form_controller";
import ReflectionController from "./controllers/reflection_controller";
import AiInputController from "./controllers/ai_input_controller";
import CompassController from "./controllers/compass_controller";
import SkipButtonController from "./controllers/skip_button_controller";
import ToggleNumericRangeController from "./controllers/toggle_numeric_range_controller";

// js code in the header doesn't work with turbo links
// https://github.com/themesberg/flowbite/issues/88#issue-1128632439
// FIX - https://github.com/htmlstreamofficial/preline/issues/171#issuecomment-1832524903
import "@hotwired/turbo-rails";

const events = ["turbo:load", "turbo:render", "turbo:frame-load"];

events.forEach((event) => {
  document.addEventListener(event, () => {
    i18n.locale = document.body.dataset.locale;
    HSStaticMethods.autoInit();
  });
});

window.Stimulus = Application.start();
Stimulus.register("reflection", ReflectionController);
Stimulus.register("multistep-form", MultistepFormController);
Stimulus.register("ai-input", AiInputController);
Stimulus.register("compass", CompassController);
Stimulus.register("skip-button", SkipButtonController);
Stimulus.register("toggle-numeric-range", ToggleNumericRangeController);

// modal processing
Turbo.setConfirmMethod(() => {
  let dialog = document.getElementById("turbo-confirm");
  const selectedPublicElem =
    document.getElementsByClassName("card-is-public");

  const selectedPublic = selectedPublicElem[0]?.value;

  if (!selectedPublicElem.length || (!!selectedPublic && selectedPublic == "true")) {
    dialog.showModal();

    return new Promise((resolve, reject) => {
      dialog.addEventListener(
        "close",
        () => {
          resolve(dialog.returnValue == "confirm");
        },
        { once: true },
      );
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
});



const shapesPool = [];
const numShapes = 9;
const colors = ['bg-blue-100/50', 'bg-cyan-100/50'];

function createBlobShape() {
    const points = [];
    const numPoints = Math.random() * 5 + 3; // You can adjust this for more or fewer points
    for (let i = 0; i < numPoints; i++) {
        const angle = (i / numPoints) * 2 * Math.PI;
        const radius = 20 + Math.random() * 20; // Adjust these values to change the blob shape
        const x = 50 + radius * Math.cos(angle);
        const y = 50 + radius * Math.sin(angle);
        points.push(`${x}% ${y}%`);
    }
    return `polygon(${points.join(', ')})`;
}

function createShape() {
    const shape = document.createElement('div');
    shape.className = 'absolute opacity-20';
    shape.style.clipPath = createBlobShape();
    return shape;
}

function initShapesPool() {
    for (let i = 0; i < numShapes; i++) {
        const shape = createShape();
        shapesPool.push(shape);
    }
}

function updateShapePosition(shape) {
    const size = Math.floor(Math.random() * 30) + 10; // 10vw to 40vw
    const top = Math.floor(Math.random() * 100);
    const left = Math.floor(Math.random() * 100);
    const colorClass = colors[Math.floor(Math.random() * colors.length)];

    shape.className = `absolute opacity-20 ${colorClass}`;
    shape.style.width = `${size}vw`;
    shape.style.height = `${size}vw`;
    shape.style.top = `${top}%`;
    shape.style.left = `${left}%`;
}

function placeShapes() {
    const container = document.getElementById('backgroundShapes');
    container.innerHTML = '';

    shapesPool.forEach(shape => {
        updateShapePosition(shape);
        container.appendChild(shape);
    });
}

// Initialize shapes pool
initShapesPool();

// Event listeners for page changes
const bgChangeEvents = ["turbo:visit", "turbo:load"];
bgChangeEvents.forEach(event => {
    document.addEventListener(event, placeShapes);
});
