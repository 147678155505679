import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "button",
    "check"
  ];

  connect() {
    const form = document.getElementById("new_reflection_entry");

    this.buttonTarget.addEventListener('click', (event) => {
      event.preventDefault();

      console.log(this.checkTarget.value)
      this.checkTarget.value = '1'; // set skipped to true
      console.log(this.checkTarget.value)

      form.requestSubmit();
    });
  }
}
