// compass_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["svg", "scoreDisplay"];
  static values = {
    score: Number,
    angle: Number,
  };

  connect() {
    this.centerX = 150;
    this.centerY = 150;
    this.radius = 130;
    this.setupCompass();
    this.updateScore();
  }

  setupCompass() {
    const compassSvg = this.svgTarget;

    // Compass arrows
    const arrowPaths = [
      { d: "M150,20 L140,40 L160,40 Z", stroke: "#fda4af", fill: "#fff" }, // North
      { d: "M280,150 L260,140 L260,160 Z", stroke: "#fda4af", fill: "#fff" }, // East
      { d: "M150,280 L140,260 L160,260 Z", stroke: "#fda4af", fill: "#fff" }, // South
      { d: "M20,150 L40,140 L40,160 Z", stroke: "#fda4af", fill: "#fff" }, // West
    ];

    arrowPaths.forEach((arrowData) => {
      this.createSvgElement("path", arrowData, compassSvg);
    });

    // Concentric circles
    [1, 0.75, 0.5, 0.25].forEach((factor) => {
      this.createSvgElement(
        "circle",
        {
          cx: this.centerX,
          cy: this.centerY,
          r: this.radius * factor,
          fill: "none",
          stroke: "#a0aec0",
          "stroke-width": "0.5",
          "stroke-dasharray": "2 4",
        },
        compassSvg,
      );
    });
  }

  updateScore() {
    const compassSvg = this.svgTarget;
    const normalizedScore = Math.min(Math.max(this.scoreValue, 0), 100);
    const distance = 0.9 - (normalizedScore / 100) * 0.8;

    const angle =
      this.angleValue !== undefined
        ? this.angleValue
        : Math.PI * 1.5 - (normalizedScore / 100) * Math.PI;

    const markerX = this.centerX + Math.cos(angle) * this.radius * distance;
    const markerY = this.centerY + Math.sin(angle) * this.radius * distance;

    // Remove old markers if they exist
    compassSvg
      .querySelectorAll(".score-marker, .pulse-circle")
      .forEach((marker) => marker.remove());

    // Create a group for the marker and pulse effect
    const markerGroup = this.createSvgElement(
      "g",
      {
        transform: `translate(${markerX}, ${markerY})`,
      },
      compassSvg,
    );

    // Add main marker (non-pulsating)
    this.createSvgElement(
      "circle",
      {
        cx: 0,
        cy: 0,
        r: "5",
        fill: "#ed8936",
        class: "score-marker",
      },
      markerGroup,
    );

    // Add pulsating circle
    this.createSvgElement(
      "circle",
      {
        cx: 0,
        cy: 0,
        r: "5",
        fill: "#ed8936",
        opacity: "0.7",
        class: "pulse-circle pulse",
      },
      markerGroup,
    );

    // this.scoreDisplayTarget.textContent = this.scoreValue;
  }

  createSvgElement(type, attributes, parent) {
    const element = document.createElementNS(
      "http://www.w3.org/2000/svg",
      type,
    );
    for (const [key, value] of Object.entries(attributes)) {
      element.setAttribute(key, value);
    }
    parent.appendChild(element);
    return element;
  }
}
