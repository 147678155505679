import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["rangeFields"]

  connect() {
    console.log("сосать-лежать")
    this.toggleRangeFields()
  }

  toggleRangeFields() {
    const dimensionTypeSelect = this.element.querySelector('select[name="dimension[dimension_type]"]')
    const isNumber = dimensionTypeSelect.value === 'number'

    this.rangeFieldsTargets.forEach(field => {
      field.style.display = isNumber ? 'block' : 'none'
    })
  }
}
